import Logo1 from "../assets/logos/1.png";
import Logo2 from "../assets/logos/2.png";
import Logo3 from "../assets/logos/3.png";
import Logo4 from "../assets/logos/4.png";
import Logo5 from "../assets/logos/5.png";
import Logo6 from "../assets/logos/6.png";
import Logo7 from "../assets/logos/7.png";
import Logo8 from "../assets/logos/8.png";
import Logo9 from "../assets/logos/9.png";
import Logo10 from "../assets/logos/10.png";
import Logo11 from "../assets/logos/11.png";
import Logo12 from "../assets/logos/12.png";
import Logo13 from "../assets/logos/13.png";

//Features
import Feat1 from "../assets/TheZoneProductFeatures/1.gif";
import Feat2 from "../assets/TheZoneProductFeatures/2.png";
import Feat3 from "../assets/TheZoneProductFeatures/3.png";
import Feat4 from "../assets/TheZoneProductFeatures/4.png";
import Feat5 from "../assets/TheZoneProductFeatures/5.png";
import Feat6 from "../assets/TheZoneProductFeatures/6.png";

//Case Studies
import Case1 from "../assets/study-cases/1.png";
import Case2 from "../assets/study-cases/2.png";
import Case3 from "../assets/study-cases/3.png";

export const logosImages = [
  {
    img: Logo1,
    redirect: "https://www.fordham.edu/",
  },
  {
    img: Logo2,
    redirect: "https://www.chapman.edu/",
  },
  {
    img: Logo3,
    redirect: "https://new.unca.edu/",
  },
  {
    img: Logo4,
    redirect: "https://www.aamu.edu/about/",
  },
  {
    img: Logo5,
    redirect: "https://www2.lehigh.edu/",
  },
  {
    img: Logo6,
    redirect: "https://www2.lehigh.edu/",
  },
  {
    img: Logo7,
    redirect: "https://www2.lehigh.edu/",
  },
  {
    img: Logo8,
    redirect: "https://themw.com/",
  },
  {
    img: Logo9,
    redirect: "https://patriotleague.org/",
  },
  {
    img: Logo10,
    redirect: "https://themw.com/", //TODO: Cambiar
  },
  {
    img: Logo11,
    redirect: "https://atlantic10.com/",
  },
  {
    img: Logo12,
    redirect: "https://athletics.setonhill.edu/",
  },
  {
    img: Logo13,
    redirect: "https://redstormsports.com/",
  },
];

export const features = [
  {
    img: Feat1,
  },
  {
    img: Feat2,
  },
  {
    img: Feat3,
  },
  {
    img: Feat4,
  },
  {
    img: Feat5,
  },
  {
    img: Feat6,
  },
];

export const caseStudies = [
  {
    img: Case1,
    redirect:
      "https://www.itsthezone.com/case-studies/lehigh-university-case-study",
  },
  {
    img: Case2,
    redirect:
      "https://www.itsthezone.com/case-studies/seton-hill-university-case-study",
  },
  {
    img: Case3,
    redirect:
      "https://www.itsthezone.com/case-studies/university-of-north-carolina-asheville",
  },
];
