/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Carousel from "react-multi-carousel";
import { responsiveCarrouselSidebar } from "./utils/responsive-items";
import { features } from "./utils/obj-images";
import { Cancel } from "@material-ui/icons";

export function AuthPage() {
  const [fullScreenFeatures, setFullScreenFeatures] = useState(false);

  return (
    <>
      {fullScreenFeatures && (
        <div className="full-screen-carrousel-container">
          <span
            onClick={() => setFullScreenFeatures(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "white",
              width: 20,
              height: 20,
            }}
          >
            <Cancel />
          </span>
          <Carousel
            infinite={true}
            autoPlay
            autoPlaySpeed={5000}
            responsive={responsiveCarrouselSidebar}
            className="full-screen-carrousel"
          >
            {features.map((i, index) => (
              <div style={{ width: "100%", textAlign: "center" }} key={index}>
                <img
                  src={i.img}
                  alt={`feat-${index}`}
                  className="img-features-fs"
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}

        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 aside-bkground">
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <a
                href="https://www.itsthezone.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex-column-auto mt-5"
              >
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/LOGO-BLANCO-THE-ZONE.png")}
                />
              </a>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              {!fullScreenFeatures && (
                <div
                  onClick={() => setFullScreenFeatures(true)}
                  className="flex-column-fluid d-flex flex-column justify-content-center mt-10"
                >
                  <Carousel
                    infinite={true}
                    autoPlay
                    autoPlaySpeed={5000}
                    responsive={responsiveCarrouselSidebar}
                  >
                    {features.map((i, index) => (
                      <div
                        style={{ width: "100%", textAlign: "center" }}
                        key={index}
                      >
                        <img
                          src={i.img}
                          alt={`feat-${index}`}
                          className="img-features"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  {new Date().getFullYear()} &copy; <b>THE ZONE</b>
                </div>
                <div>
                  <a
                    href="https://www.itsthezone.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    Privacy
                  </a>
                  <a
                    href="https://www.itsthezone.com/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white mx-3"
                  >
                    Terms
                  </a>
                  <a
                    href="mailto:support@itsthezone.zendesk.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    Contact
                  </a>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
              </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {new Date().getFullYear()} The Zone
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a
                  href="https://www.itsthezone.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary"
                >
                  Privacy
                </a>
                <a
                  href="https://www.itsthezone.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary mx-3"
                >
                  Terms
                </a>
                <a
                  href="mailto:support@itsthezone.zendesk.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary"
                >
                  Contact
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
